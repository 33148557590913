var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-badge',{attrs:{"color":"#D4AF37","content":("N." + (_vm.building.currentLevel)),"bordered":"","overlap":"","offset-x":"48","offset-y":"32"}},[_c('v-card',{staticClass:"d-flex flex-column justify-space-between relative",attrs:{"color":"secondary","width":"250","height":"250","elevation":hover ? 12 : 2},on:{"click":function($event){_vm.showDetails = true}}},[(
							!_vm.canAfford ||
							_vm.otherConstructionUnderway ||
							_vm.isForgeAndUnitsPending
						)?_c('v-overlay',{staticStyle:{"z-index":"1","border-radius":"0%"},attrs:{"absolute":"","opacity":"0.6"}},[(_vm.otherConstructionUnderway)?_c('span',[_vm._v(" Construction en cours ")]):(_vm.isForgeAndUnitsPending)?_c('span',[_vm._v(" Unités en formation ")]):_c('span',[_vm._v(" Ressources manquantes ")])]):_vm._e(),_c('v-img',{attrs:{"src":("/img/ressources/buildings/" + (_vm.building.nameVO) + ".webp"),"height":"170"}}),_c('v-card-subtitle',{staticClass:"d-flex gap-05 pb-1 pt-2 align-center"},[_c('div',{staticClass:"d-flex flex-column",class:_vm.loadedPlayer.ressources.currency.value -
									_vm.building.currencyCost <
								0
									? 'red--text'
									: ''},[_c('v-icon',{attrs:{"small":""}},[_vm._v("savings")]),_c('span',[_vm._v(_vm._s(_vm.toShortNumbers(_vm.building.currencyCost)))])],1),_c('div',{staticClass:"d-flex flex-column",class:_vm.loadedPlayer.ressources.armies.value - _vm.building.armiesCost < 0
									? 'red--text'
									: ''},[_c('v-icon',{attrs:{"small":""}},[_vm._v("military_tech")]),_c('span',[_vm._v(_vm._s(_vm.toShortNumbers(_vm.building.armiesCost)))])],1),_c('div',{staticClass:"d-flex flex-column",class:_vm.loadedPlayer.ressources.food.value - _vm.building.foodCost < 0
									? 'red--text'
									: ''},[_c('v-icon',{attrs:{"small":""}},[_vm._v("food_bank")]),_c('span',[_vm._v(_vm._s(_vm.toShortNumbers(_vm.building.foodCost)))])],1),_c('v-divider',{attrs:{"vertical":""}}),(_vm.building.populationCost > 0)?_c('div',{staticClass:"d-flex flex-column",class:_vm.loadedPlayer.ressources.population.value -
									_vm.building.populationCost <
								0
									? 'red--text'
									: ''},[_c('v-icon',{attrs:{"small":""}},[_vm._v("groups")]),_c('span',[_vm._v(" "+_vm._s(_vm.toShortNumbers(_vm.building.populationCost))+" ")])],1):_vm._e(),_c('v-spacer'),(!_vm.constructionPending)?_c('v-btn',{staticClass:"mb-1",attrs:{"rounded":"","small":"","color":"primary","dark":"","loading":_vm.loading,"disabled":!_vm.canAfford || _vm.otherConstructionUnderway},on:{"click":function($event){$event.stopPropagation();return _vm.startConstruction(_vm.building)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("upload")]),_c('span',[_vm._v("N. "+_vm._s(_vm.building.nextLevel))])],1):_c('v-chip',[_vm._v(" "+_vm._s(_vm.toReadableTime(_vm.remainingSeconds))+" ")])],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"fullscreen":_vm.isMobile,"scrollable":"","max-width":!_vm.isMobile ? 500 : undefined},model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[_c('v-card',{attrs:{"flat":"","color":"accent","dark":""}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.building.nameVF))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showDetails = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"py-3 px-1",staticStyle:{}},[_c('v-img',{attrs:{"src":("/img/ressources/buildings/" + (_vm.building.nameVO) + ".webp"),"height":"170"}}),(_vm.loadingFormulas)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Niv.")]),_c('th',[_c('v-icon',{attrs:{"small":""}},[_vm._v("savings")])],1),_c('th',[_c('v-icon',{attrs:{"small":""}},[_vm._v("military_tech")])],1),_c('th',[_c('v-icon',{attrs:{"small":""}},[_vm._v("food_bank")])],1),_c('th',[_c('v-icon',{attrs:{"small":""}},[_vm._v("access_time")])],1)])]),_c('tbody',_vm._l((_vm.formulas),function(formula){return _c('tr',{key:formula.level,class:{
									'gold--text': formula.level == _vm.building.currentLevel,
								}},[_c('td',[_vm._v(" "+_vm._s(formula.level)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.toShortNumbers(formula.currency))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.toShortNumbers(formula.armies))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.toShortNumbers(formula.food))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.toReadableTime( Math.ceil( formula.constructionTime / _vm.constructionTimeDivider ) ))+" ")])])}),0)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":"","rounded":"","disabled":!_vm.canAfford || _vm.otherConstructionUnderway},on:{"click":function($event){return _vm.startConstruction(_vm.building)}}},[_c('span',[_vm._v(" Construire le niveau "+_vm._s(_vm.building.nextLevel)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("upload")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }